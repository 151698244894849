

#carousel {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 200px;
    width: 1210px;
    margin: auto;
    left: 150px;
    right: 0;
    top: 0;
    bottom: 0;
}

.arrow {
    // position: absolute;
    width: 30px;
    height: 30px;
    // background-color: white;
    // border: 1px solid white;
    text-align: center;
    font-size: 25px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    margin-top: 85px;
    z-index: 1000;
}

.arrow-right {
    // left: 780px;
    margin-right: 14%;
}

.arrow-left{
    margin-left: -11%;
}

.item {
    text-align: center;
    color: white;
    // font-size: 40px;
    // width: 320px !important;
    position: absolute;
    transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
}

$level2-height: 150px;
$level2-width: 180px;
$level1-height: 180px;
$level1-width: 240px;
$level0-height: 200px;
$level0-width: 350px;

$level-2-left: 900px;
$level-1-left: 630px;
$level0-left: 310px;
$level1-left: 25px;
$level2-left: -190px;


.level-2 {
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    left: $level-2-left;
    margin-top: 45px;
    opacity: 0.5;
}

.level-1 {
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    left: $level-1-left;
    margin-top: 10px;
    opacity: 0.5;
}

.level0 {
    height: $level0-height;
    width: $level0-width;
    line-height: $level0-height;
    left: $level0-left;
    margin-bottom: 10px;
}

.level1 {
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    margin-top: 10px;
    left: $level1-left;
    opacity: 0.5;
}

.level2 {
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    margin-top: 40px;
    left: $level2-left;
    opacity: 0.5;
}

.left-enter {
    opacity: 0;
    left: $level2-left - $level2-width;
    height: $level2-height - 30;
    width: $level2-width - 20;
    line-height: $level2-height - 30;
    margin-top: 40px;
}

.left-enter.left-enter-active {
    opacity: 1;
    left: $level2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    margin-top: 25px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.left-leave {
    opacity: 1;
    left: $level-2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    margin-top: 25px;
}

.left-leave.left-leave-active {
    left: $level-2-left + $level2-width + 20;
    opacity: 0;
    height: $level2-height - 30;
    line-height: 120px;
    margin-top: 40px;
    width: $level2-width - 20;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-enter {
    opacity: 0;
    left: $level-2-left + $level2-width;
    height: $level2-height - 30;
    width: $level2-width - 20;
    line-height: $level2-height - 30;
    margin-top: 40px;
}

.right-enter.right-enter-active {
    left: $level-2-left;
    opacity: 1;
    height: $level2-height;
    margin-top: 25px;
    line-height: $level2-height;
    width: $level2-width;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-leave {
    left: $level2-left;
    height: $level2-height;
    opacity: 1;
    margin-top: 25px;
    line-height: $level2-height;
    width: $level2-width;
}

.right-leave.right-leave-active {
    left: $level2-left - $level2-width;
    opacity: 0;
    height: $level2-height - 30;
    width: $level2-width - 20;
    line-height: $level2-height - 30;
    margin-top: 40px;
    transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}